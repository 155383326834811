<template>
  <div class="md-new-detail-page-component">
    <!-- 头部 -->
    <!-- <MdDetailHeaderClassComponent :title="articleInfo.title" /> -->

    <div class="n-box">

      <div class="n-left">
        <div class="box-tile"> {{ articleInfo.title }}</div>
        <i class="el-icon-loading" v-show="isLoading"></i>
        <div class="box-content" v-html="articleInfo.content"></div>

      </div>
      <div class="n-right" v-if="rid != 3 && rid != 4 && rid != 5">
        <p> <img src="../assets/img/new/news.png" alt="" style="width: 20px; height: 20px;"> <span
            class="news-p">相关资讯</span></p>
        <ul v-if="selectArticleList.length">
          <li v-for="(item, index) in selectArticleList" :key="index" @click="handleClick(item)">
            <span class="icon-circle"></span>
            <div class="c-type-box">
              <p class="c-tit">{{ item.title }}</p>
            </div>
          </li>
        </ul>
        <p v-else class="no-data">暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import { selectArticleInfo, selectArticleReferral, selectArticleDetailInfo } from '@/axios/news'
// import MdDetailHeaderClassComponent from '../components/class/mdDetailHeader/index.vue'
export default {
  name: 'mdNewDetailPageComponent',
  data() {
    return {
      rid: '',
      articleInfo: {
        title: '',
        content: '',
        createTime: ''
      },
      content: "",
      selectArticleList: [],
      isLoading: true,

    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        console.log(val, '进来了');
        this.rid = val.rid
        this.articleInfo={}
        if (val.item) {
          this.articleInfo = JSON.parse(val.item)
        }

        this.selectArticleInfo(val.rid)
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.selectArticleReferral()

  },
  methods: {
    async selectArticleInfo(rid) {
      const res = await selectArticleInfo(rid)
      if (res.code === 200) {

        this.$set(this.articleInfo, 'content', res.data.content)
        this.isLoading = false
      }

      const res1 = await selectArticleDetailInfo(rid)
      if (res1.code == 200) {

        this.$set(this.articleInfo, 'content', res1.data[0].content)
      }
    },
    async selectArticleReferral() {
      const res = await selectArticleReferral()
      if (res.code === 200) {
        this.selectArticleList = res.data
      }
    },
    handleClick(item) {
      this.$router.replace({
        path: '/newsDetail',
        query: {
          rid: item.rid,
          item: JSON.stringify(item)
        }
      })
    }
  },
  components: {
    // MdDetailHeaderClassComponent
  }
}
</script>
<style lang="less" scoped>
.md-new-detail-page-component {
  width: 100%;
  background: @md-bgc-primary-color;

  .n-box {
    width: 1152px;
    margin: 0 auto;
    display: flex;
    padding: 30px 0;
  }


  .news-p {
    font-size: 16px;
    font-weight: 600;
  }

  .n-left {
    width: 845px;
    margin-right: 20px;
    box-shadow: 0 4px 8px 0 rgba(28, 31, 33, .1);
    border-radius: 8px;
    padding: 20px 30px;
    border-radius: 8px;
    background-color: #fff;

    .box-content {

      /deep/ h1,
      /deep/ h2,
      /deep/ h3,
      /deep/ h4,
      /deep/ h5,
      /deep/ h6 {
        margin: 10px 0 !important;
      }

      /deep/ p {
        color: #333;
        font-size: 14px;
        line-height: 2;
        text-indent: 2em;
        max-width: 824px !important;

        img {
          width: 97% !important;
          margin: 10px 0;
        }
      }

      /deep/ img {
        width: 97% !important;
        margin: 10px 0;
      }
    }


  }

  .n-right {
    width: 328px;
    flex-shrink: 0;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(28, 31, 33, .1);
    border-radius: 8px;
    padding: 20px 20px 10px;
    max-height: 800px;

    >:nth-child(1) {
      padding-bottom: 12px;
      border-bottom: 2px solid rgba(230, 230, 230, 1);
    }

    b {
      color: @md-primary-color;
      margin-top: 20px;
    }

    ul {
      // padding: 5px;
      box-sizing: border-box;

      li {
        display: flex;
        padding: 10px 0;
        line-height: 20px;
        align-items: center;
        font-size: 14px;

        span {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(204, 204, 204, 1);

        }

        &:hover {
          .icon-circle {
            background-color: @md-primary-color;
          }

          .c-type-box {
            .c-tit {
              color: @md-primary-color;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        .c-img {
          width: 80px;
          height: 60px;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .c-type-box {
          flex: 1;
          // height: 60px;
          margin-left: 10px;
          overflow: hidden;

          .c-tit {
            width: 100%;
            // padding-top: 8px;
            font-size: 14px;
            // height: 24px;
            // line-height: 24px;
            // margin-bottom: 4px;
            cursor: pointer;
            color: rgba(51, 51, 51, 1);
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .view-p-count {
            display: flex;
            align-items: center;
            color: #545C63;
            font-size: 12px;
            margin-top: 5px;

            i {
              margin-right: 3px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.box-tile {
  font-size: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 37px;
  margin-bottom: 40px;
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}
</style>